import imag from '../assets/main-section.jpg'
import imag1 from '../assets/nick-de-partee-5DLBoEX99Cs-unsplash.jpg'
export const Data = [
    {
        title: 'From People',
        text: 'There are different types of clothes, like tops, which include blouses and shirts. Tops are used for the upper part of the body and bottoms, like jeans and ...',
        image: imag,
        button: 'Shop online',
        className: 'data-item'
    },
    {
        title: 'From The People',
        text: 'There are different types of clothes, like tops, which include blouses and shirts. Tops are used for the upper part of the body and bottoms, like jeans and ...',
        image: imag1,
        button: 'Shop online',
        className: 'data-item'
    },
]