import './Footer.scss'


const Footer = () => {
  return (
    <section className='footer'>
        <p>
            <span>About</span>
            <span>Blog</span>
            <span>Details</span>
            <span>What we do</span>
            <span>Tokens</span>
        </p>
        <p>
            <span>About</span>
            <span>Blog</span>
            <span>Details</span>
            <span>What we do</span>
            <span>Tokens</span>
        </p>
        <p>
            <span>About</span>
            <span>Blog</span>
            <span>Details</span>
            <span>What we do</span>
            <span>Tokens</span>
        </p>
        <p>
            <span>About</span>
            <span>Blog</span>
            <span>Details</span>
            <span>What we do</span>
            <span>Tokens</span>
        </p>
    </section>
  )
}

export default Footer